<template>

  <!-- Card -->
  <b-card>

    <b-row>

      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >

        <!-- Input Entries -->
        <label class="d-lg-inline d-none mr-1">Entries</label>
        <v-select
          v-model="limit"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="limitOptions"
          taggable
          push-tags
          :clearable="false"
          class="hr-limit-select mr-1"
        />

        <!-- Button New -->
        <b-button
          variant="primary"
          :to="{ name: 'apps-hr-form', params: { action: 'add' } }"
        >
          <feather-icon
            icon="UserPlusIcon"
            size="14"
          />
          <span class="d-lg-inline d-none"> New</span>
        </b-button>

        <!-- Button Import -->
        <b-button
          v-b-modal.modal-import
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="ml-1"
          @click="excelFile = null"
        >
          <feather-icon
            icon="UploadIcon"
            size="14"
          />
          <span class="d-xl-inline d-none"> Import</span>
        </b-button>

        <!-- Button Export -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="ml-1"
          @click="exportCaregiver"
        >
          <feather-icon
            icon="DownloadIcon"
            size="14"
          />
          <span class="d-xl-inline d-none"> Export</span>
        </b-button>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-end"
      >

        <!-- Input Search -->
        <b-input-group
          class="input-group-merge mr-1"
        >
          <b-form-input
            v-model="searchQuery"
            type="search"
            placeholder="Search"
            @input="searchFormList"
          />
          <b-input-group-append is-text>
            <feather-icon
              icon="SearchIcon"
              class="text-muted"
            />
          </b-input-group-append>
        </b-input-group>

        <!--
      </b-col>
      <b-col
        cols="12"
        md="3"
        class="d-flex align-items-center justify-content-end pl-1 pl-md-0"
      >
      -->

        <!-- Select Employment Status -->
        <v-select
          v-model="employmentStatusFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="employmentStatusOptions"
          :clearable="true"
          class="hr-employment-status-filter-select"
          placeholder="Select Status"
        >

          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>

        </v-select>

      </b-col>
    </b-row>

    <!-- Table -->
    <b-table
      ref="refFormListTable"
      hover
      :fields="tableColumns"
      :items="fetchForms"
      primary-key="id"
      :sort-by.sync="sort"
      :sort-desc.sync="sortDesc"
      show-empty
      empty-text="No matching records found"
      responsive
      class="hr-list-table position-relative mt-1 mb-2"
    >

      <!-- Column Id -->
      <template #cell(id)="data">
        <b-link
          :to="{ name: 'apps-hr-form', params: { action: 'edit' }, query: { id: data.item.id } }"
          target="_blank"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <!-- Column Lastname -->
      <template #cell(lastname)="data">
        <span class="text-nowrap text-capitalize">
          {{ data.value }}
        </span>
      </template>

      <!-- Column Firstname -->
      <template #cell(firstname)="data">
        <span class="text-nowrap text-capitalize">
          {{ data.value }}
        </span>
      </template>

      <!-- Column Address -->
      <template #cell(address)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column Employment Status Date -->
      <template #cell(employmentstatusdate)="data">
        <span class="text-nowrap">
          {{ data.item.employmentstatusdate }}
        </span>
      </template>

      <!-- Column Contact Number -->
      <template #cell(contactnumber)="data">
        <span class="ws-pre-line">
          {{ (data.item.mobilenumber != "" ? data.item.mobilenumber : data.item.phonenumber) }}
        </span>
      </template>

      <!-- Column Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">

          <!-- Preview -->
          <!-- <feather-icon
            :id="`row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mr-1"
            role="button"
            @click="$router.push({ name: 'apps-hr-form', params: { action: 'preview' }, query: { id: data.item.id } })"
          />
          <b-tooltip
            title="Preview"
            :target="`row-${data.item.id}-preview-icon`"
          /> -->

          <!-- Edit -->
          <feather-icon
            :id="`row-${data.item.id}-edit-icon`"
            icon="EditIcon"
            size="16"
            role="button"
            @click="$router.push({ name: 'apps-hr-form', params: { action: 'edit' }, query: { id: data.item.id } })"
          />
          <b-tooltip
            v-if="false"
            title="Edit"
            :target="`row-${data.item.id}-edit-icon`"
          />

          <!-- Delete -->
          <feather-icon
            v-if="false"
            :id="`row-${data.item.id}-delete-icon`"
            icon="TrashIcon"
            size="16"
            role="button"
            @click="deleteForms(data.item.id, data.item.firstname + ' ' + data.item.lastname)"
          />
          <b-tooltip
            v-if="false"
            title="Delete"
            :target="`row-${data.item.id}-delete-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
            class="d-none"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <!-- Dropwdown Item Edit -->
            <b-dropdown-item :to="{ name: 'apps-hr-form', params: { action: 'edit' }, query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <!-- Dropwdown Item Delete -->
            <b-dropdown-item @click="deleteForms(data.item.id, data.item.firstname + ' ' + data.item.lastname)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>

        </div>
      </template>

    </b-table>

    <b-row>

      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-sm-0"
      >

        <!-- Display Entries -->
        <span class="gray-2">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>

      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >

        <!-- Pagination -->
        <b-pagination
          v-model="page"
          :total-rows="totalCount"
          :per-page="limit"
          first-number
          last-number
          class="mb-0"
          prev-class="prev-item"
          next-class="next-item"
        >

          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>

          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>

        </b-pagination>

      </b-col>
    </b-row>

    <!-- import modal  -->
    <b-modal
      id="modal-import"
      v-model="showImportModal"
      centered
      title="Import Excel File"
      hide-footer
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
          class="mb-1"
        >
          <span>Please use this </span>
          <span>
            <a
              :href="`${urlUpload}hr/template/Import_caregiver_tempate.xlsx`"
            >template
              <feather-icon
                icon="DownloadIcon"
                size="14"
              />
            </a>
          </span>
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-file
            v-model="excelFile"
            placeholder="Choose a excel file..."
            drop-placeholder="Drop file here..."
            type="file"
            accept=".xlsx"
            capture
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="my-xs-1"
        >
          <b-button
            variant="primary"
            :disabled="!excelFile"
            @click="importCaregiver"
          >
            Import
          </b-button>
        </b-col>
        <b-col
          cols="12"
          class="my-1"
        >
          <strong class="text-danger">Note:</strong>
          <span class=""> First Name, Last Name, Birth Date, and Employment Status are <span class="text-danger">required</span> fields.</span>
          <!-- <span class="text-muted"> First Name, Last Name, Birth Date, and Employment Status are required fields.</span> -->
        </b-col>
      </b-row>
    </b-modal>

    <!-- progressbar modal  -->
    <b-modal
      id="progressbarModal"
      v-model="progressbarModal"
      centered
      title=""
      hide-footer
      size="lg"
    >
      <b-row
        class="mb-3 mt-1"
      >
        <b-col>
          <b-progress
            class="mb-2 mt-2"
            height="50px"
            :max="100"
            show-progress
          >
            <b-progress-bar
              :value="progressPercent"
              :label="`${((progressPercent / 100) * 100).toFixed(2)}%`"
              animated
            />
          </b-progress></b-col>
      </b-row>
    </b-modal>

  </b-card>

</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BTable, BPagination, BDropdown, BDropdownItem, BLink, BTooltip, BButton, BFormFile, BModal, VBModal, BProgress, BProgressBar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import { onUnmounted, watch } from '@vue/composition-api'
import store from '@/store'
import useHrList from './useHrList'
import useImport from './useImport'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BLink,
    BTooltip,
    BButton,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BProgress,
    BProgressBar,

    vSelect,
    // eslint-disable-next-line
    Swal,
  },
  directives: {
    Ripple,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const employmentStatusOptions = [
      'Active',
      'Applicant',
      'Inactive',
      'New',
      'Pending',
      'Resigned',
      'Terminated',
    ]

    const {
      refFormListTable,
      tableColumns,
      searchQuery,
      employmentStatusFilter,
      sort,
      sortDesc,
      page,
      limit,
      limitOptions,
      totalCount,
      dataMeta,

      refetchData,

      fetchForms,
    } = useHrList()

    const {
      excelFile,
      showImportModal,
      progressPercent,
      progressbarModal,
      importCaregiver,
      exportCaregiver,
    } = useImport()

    watch(excelFile, () => {
      refetchData()
    })

    return {
      employmentStatusOptions,

      refFormListTable,
      tableColumns,
      searchQuery,
      employmentStatusFilter,
      sort,
      sortDesc,
      page,
      limit,
      limitOptions,
      totalCount,
      dataMeta,

      refetchData,

      fetchForms,

      // niko
      excelFile,
      showImportModal,
      progressPercent,
      progressbarModal,
      importCaregiver,
      exportCaregiver,
    }
  },
  data() {
    return {
      timeout: null,
      urlUpload: this.$urlUpload,
    }
  },
  methods: {
    searchFormList() {
      const self = this

      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        self.refetchData()
      }, 1500)
    },
    deleteForms(dataItemId, dataItemName) {
      const self = this

      Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        // eslint-disable-next-line
        text: 'Do you want to delete ' + dataItemName + '?',
        confirmButtonText: 'Proceed',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-dark mr-1',
        },
        buttonsStyling: false,
        returnFocus: false,
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-hr/deleteForms', { id: dataItemId })
            // eslint-disable-next-line
            .then(response => {
              // console.log(response.data)

              self.refetchData()

              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                // eslint-disable-next-line
                text: 'Deleted succesfully.',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch(error => {
              console.error(error)

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                showConfirmButton: false,
                timer: 1500,
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.hr-limit-select {
  width: 90px;
}

.hr-employment-status-filter-select {
  /* width: 100%; */
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
/*
@media (max-width: 575.98px) {
  .hr-employment-status-filter-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 100%;
    }
  }
}
*/

.hr-list-table {
  ::v-deep .table > tbody > tr:first-child > td {
    border: none;
  }
}

.ws-pre-line {
  white-space: pre-line;
}
</style>
