<template>
  <section id="hr">
    <!-- <h1>{{ pageTitle }} </h1>
    <br> -->
    <b-row class="match-height">
      <b-col
        cols="12"
      >
        <hr-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import HrList from '@/views/apps/hr/hr-list/HrList.vue'

export default {
  components: {
    BRow,
    BCol,

    HrList,
  },
  data() {
    return {
      pageTitle: 'HR',
    }
  },
}
</script>

<style>
h1{
  margin: auto;
}
</style>
