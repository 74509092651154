/* eslint-disable no-unused-vars */
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useHrList() {
  // Use toast
  const toast = useToast()

  const refFormListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: '#',
      sortable: true,
      thClass: 'align-middle',
    },
    {
      key: 'lastname',
      sortable: true,
      thClass: 'align-middle',
      tdClass: 'text-truncate',
    },
    {
      key: 'firstname',
      sortable: true,
      thClass: 'align-middle',
      tdClass: 'text-truncate',
    },
    {
      key: 'address',
      sortable: true,
      thClass: 'align-middle',
      tdClass: 'text-truncate',
    },
    {
      key: 'employmentstatusdate',
      label: 'EMP. STATUS DATE',
      sortable: true,
      thClass: 'align-middle text-truncate',
    },
    { key: 'contactnumber', label: 'CONTACT NUMBER', thClass: 'text-nowrap align-middle' },
    { key: 'actions', thClass: 'align-middle text-center', tdClass: 'text-center' },
  ]

  const searchQuery = ref('')
  const employmentStatusFilter = ref(null)
  const sort = ref('lastname')
  const sortDesc = ref(false)
  const page = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const totalCount = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refFormListTable.value ? refFormListTable.value.localItems.length : 0
    return {
      from: limit.value * (page.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (page.value - 1) + localItemsCount,
      of: totalCount.value,
    }
  })

  const refetchData = () => {
    refFormListTable.value.refresh()
  }

  watch([employmentStatusFilter, sort, sortDesc, page, limit], () => {
    refetchData()
  })

  const fetchForms = (ctx, callback) => {
    store
      .dispatch('app-hr/fetchForms', {
        q: searchQuery.value,
        employmentStatus: employmentStatusFilter.value,
        _sort: sort.value,
        _order: (sortDesc.value ? 'desc' : 'asc'),
        _page: page.value,
        _limit: limit.value,
      })
      .then(response => {
        const jsonObjectLiterals = response.data.response.data

        // console.log(jsonObjectLiterals)

        callback(jsonObjectLiterals.data)
        totalCount.value = jsonObjectLiterals.totalCount
      })
      .catch(error => {
        console.error(error)

        /* toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching table list.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        }) */
      })
  }

  return {
    refFormListTable,
    tableColumns,
    searchQuery,
    employmentStatusFilter,
    sort,
    sortDesc,
    page,
    limit,
    limitOptions,
    totalCount,
    dataMeta,

    refetchData,

    fetchForms,
  }
}
