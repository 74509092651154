/* eslint-disable no-unused-expressions */
/* eslint-disable no-const-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-template-curly-in-string */
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import axios from '@axios'
import Swal from 'sweetalert2'

export default function useImport() {
  const excelFile = ref(null)

  const showImportModal = ref(false)

  const progressbarModal = ref(false)

  const progressPercent = ref(0)

  const closeModalPopup = () => {
    progressPercent.value = 100
    showImportModal.value = false
    progressbarModal.value = false
    showImportModal.value = false
    excelFile.value = []
  }

  /* Popup function for normal popup */
  const popup = temp => {
    closeModalPopup()
    const {
      icon, title, text, timer,
    } = temp

    Swal.fire({
      icon,
      title,
      text,
      timer,
      showConfirmButton: false,
      customClass: {
        confirmButton: 'btn btn-secondary',
        cancelButton: 'btn btn-outline-dark',
      },
      buttonsStyling: false,
    })
  }

  /* Popup for invalid entry */
  const invalidEntryWarning = link => {
    closeModalPopup()

    const icon = 'warning'
    const title = 'Some entries were not saved!'
    const text = 'Do you like to download the system generated excel file for unsaved entries?'
    const showConfirmButton = true
    const showCancelButton = true
    const confirmButtonText = 'Download'

    Swal.fire({
      icon,
      title,
      text,
      showConfirmButton,
      showCancelButton,
      confirmButtonText,
      customClass: {
        confirmButton: 'btn btn-secondary  mr-1',
        cancelButton: 'btn btn-outline-dark',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title: 'NOTE:',
          html: `Entries in  <span class="font-weight-bold" style="color: #ff0000">RED</span> means there are missing important information (such as first name, last name, birth date, and employment status); <span class="font-weight-bold" style="color: #FF8C00">ORANGE</span> means it's a duplicate entry.
            `,
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-secondary',
          },

        }).then(() => downloadExportedExcel(link))
      }
    })
  }

  /* Progressbar config */
  const config = {
    onUploadProgress: progressEvent => {
      if (progressPercent.value < 90) {
        progressPercent.value = (progressEvent.loaded / progressEvent.total) * 90
      } else { progressPercent.value = 100 }
    },
  }

  /* Handle import response */
  const handleImportResponse = res => {
    setTimeout(() => {
      if (res.response === true) {
        if (res.invalid_entry === '') {
          const icon = 'success'
          const title = 'Import Successfully'
          const text = ''
          const timer = 1500
          popup({
            icon, title, text, timer,
          })
        } else {
          invalidEntryWarning(res.invalid_entry)
        }
      } else {
        popup({
          icon: 'error', title: 'Something wrong! ..', text: 'Please check you excel file', timer: 1500,
        })
      }
    }, 1500)
  }

  /* import function */
  const importCaregiver = () => {
    progressPercent.value = 0

    if (excelFile.value) {
      progressbarModal.value = true
      const formData = new FormData()
      const data = {
        // action: 'test',
        action: 'import',
      }
      formData.append('files[0]', excelFile.value)
      formData.append('data', JSON.stringify(data))

      axios
      // eslint-disable-next-line
        .post(Vue.prototype.$apiUrl2 + 'hrImportExport/action', formData, config, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
        .then(response => {
          handleImportResponse(response.data.response.data)
        })
        .catch(() => {
          popup({
            icon: 'error', title: 'Something wrong! ..', text: 'Please check you excel file', timer: 1500,
          })
        })
    } else {
      popup({
        icon: 'warning', title: 'Warning!', text: 'Please select an excel file to import.', timer: 1500,
      })
    }
  }

  /* Export function */
  const exportCaregiver = () => {
    // Swal.showLoading()
    progressbarModal.value = true
    progressPercent.value = 0
    const formData = new FormData()

    const data = {
      action: 'export',
    }
    formData.append('data', JSON.stringify(data))

    axios
    // eslint-disable-next-line
      .post(Vue.prototype.$apiUrl2 + 'hrImportExport/action', formData, config, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })
      // eslint-disable-next-line no-use-before-define
      .then(response => downloadExportedExcel(response.data.response.data))
      .catch(error => (error))
  }

  /* Download exported */
  const downloadExportedExcel = datas => {
    setTimeout(() => {
      // const toSplitUrl = (Vue.prototype.$mainUrl).includes('coc-dev') ? 'coc-dev/' : 'coc' /* checks if it is in prod site */
      const toSplitUrl = process.env.VUE_APP_HR_EXPORT
      window.open(Vue.prototype.$mainUrl + datas.split(toSplitUrl)[1])
      closeModalPopup()
    }, 1500)
    // Swal.close()
  }
  return {
    excelFile,
    showImportModal,
    progressbarModal,
    progressPercent,
    importCaregiver,
    exportCaregiver,
  }
}
